import html from 'bundle-text:./navbar.html';
import css from 'bundle-text:./navbar.css';

const template = document.createElement("template");
template.innerHTML = `
<style>
${css}
</style>
${html}
`;

export class Navbar extends HTMLElement {
	static get observedAttributes() {
		return [];
	}

	constructor() {
		super();
		this.attachShadow({ mode: "open" });
		this.shadowRoot.appendChild(template.content.cloneNode(true));
	}

	connectedCallback() {
	}

	disconnectedCallback() {
	}

	attributeChangedCallback(name, oldValue, newValue) {
	}
}

customElements.define("my-navbar", Navbar);